import MUIDataTable from "mui-datatables";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Sidebar1 from "../../../../components/sidebar/sidebar";
import Header from "../../../../components/header/header";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  servicerGetalldeals,
  getDealDetailsByPaymentDateservicer,
} from "../../../../servies/services";
import { TrainRounded } from "@material-ui/icons";
import Loader from "../../../../components/loader";
import NumberComp2 from "../../../../components/NumberComp2";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import * as moment from "moment";
import { data } from "jquery";
import { Servicer } from "../../../../components/sidebar/menu";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import BeanEater from "../../../../images/BeanEater.gif";
import ReactModal from "react-modal";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import borderLeft from "../../../../images/border-left.png"
import borderBottom from "../../../../images/border-bottom.png"

class ServicerMainDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      tableData: [],
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      loading: false,
      getLoansLoader: false,
      screenloader: false,
      NextPaymentDate: "",
      LastPaymentDate: "",
      getPaymentDateIndex: null,
      getlastPaymentDates: [],
      paymentDates: [],
      PaymentDate: "",
      anchorEl: null,
      servicerValues: [],
      isDateSelected: null,
      showSearchBox: false,
      searchText: "",
      currentPage: 1,
      rowsPerPage: 6,
      selectedDate: null,
      dealName: "",
    };
  }
  handlePageChange = (event, value) => {
    this.setState({ currentPage: value });
  };

  paginateData = () => {
    const { tableData, currentPage, rowsPerPage, searchText } = this.state;

    // Step 1: Filter the data based on the search term across all pages
    const filteredData = tableData.filter((item) => {
      // Modify this according to your column data structure
      return Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      );
    });

    // Step 2: Apply pagination to the filtered data
    const startIndex = (currentPage - 1) * rowsPerPage;
    return filteredData.slice(startIndex, startIndex + rowsPerPage);
  };

  Recurring = (value, tableMeta) => {
    console.log(tableMeta.rowData);
    sessionStorage.setItem("Servicer", tableMeta.rowData[2]);
    sessionStorage.setItem("servicerdealname", tableMeta.rowData[0]);
    sessionStorage.setItem(
      "selectservicerdate",
      this.state.NextPaymentDate === ""
        ? this.state.selectedDate
        : this.state.NextPaymentDate
    );

    const dates = tableMeta.rowData[4];

    let firstDate;
    if (typeof dates === "string") {
      // If `dates` is a string, split it by commas and get the first date
      firstDate = dates.split(",")[0].trim();
    } else if (Array.isArray(dates)) {
      // If `dates` is an array, take the first element
      firstDate = dates[0];
    } else {
      // If `dates` is another type (e.g., an object), handle it accordingly
      firstDate = ""; // Fallback value or custom logic
    }

    sessionStorage.setItem("getDashboardDates", firstDate);

    // sessionStorage.setItem(
    //   "selectservicerdate",
    //   this.state.NextPaymentDate === ""
    //     ? tableMeta.rowData[4]
    //     : this.state.NextPaymentDate
    // );
    sessionStorage.setItem("servicerdashboardname", tableMeta.rowData[2]);
    this.props.history.push({
      pathname: "/admin/summarize",
      state: { ViewServicer: "Yes" },
    });
  };
  //   UploadLoanTape = (value, tableMeta) => {
  //     sessionStorage.setItem("selectservicerdate", this.state.selectedDate);
  //     sessionStorage.setItem("servicerdashboardname", tableMeta.rowData[0]);
  //     const dates = tableMeta.rowData[4]; // Assuming this contains a string of dates like "2024-08-01, 2024-08-02, 2024-08-03"
  // const firstDate = dates.split(',')[0].trim(); // Split the string by commas and take the first date
  // sessionStorage.setItem("getDashboardDates", firstDate);

  //     this.props.history.push({
  //       pathname: "/admin/uploadloantape",
  //       state: {
  //         ServicerPaymentDate:
  //           this.state.selectedDate === ""
  //             ? tableMeta.rowData[4]
  //             : this.state.selectedDate,
  //         ServicerDealName: this.state.dealName,
  //         ServicerDashboardName: tableMeta.rowData[0],
  //       },
  //       // { detailsView: this.state.detailsDateViewDetails === undefined ? sessionStorage.getItem('selectdate') : this.state.detailsDateViewDetails, dealname: value, servicer: tableMeta.rowData[1]},
  //     });
  //   };

  UploadLoanTape = (value, tableMeta) => {
    sessionStorage.setItem("selectservicerdate", this.state.selectedDate);
    sessionStorage.setItem("servicerdashboardname", tableMeta.rowData[0]);
    sessionStorage.setItem("Servicer", tableMeta.rowData[2]);
    sessionStorage.getItem("selectdate",this.state.detailsDateViewDetails)
    const dates = tableMeta.rowData[4];
    console.log("tablemetaser", tableMeta);

    let firstDate;
    if (typeof dates === "string") {
      // If `dates` is a string, split it by commas and get the first date
      firstDate = dates.split(",")[0].trim();
    } else if (Array.isArray(dates)) {
      // If `dates` is an array, take the first element
      firstDate = dates[0];
    } else {
      // If `dates` is another type (e.g., an object), handle it accordingly
      firstDate = ""; // Fallback value or custom logic
    }

    sessionStorage.setItem("getDashboardDates", firstDate);
    
console.log("firstdate",firstDate,this.state.selectedDate);
    this.props.history.push({
      pathname: "/admin/uploadloantape",
      state: {
        ServicerPaymentDate:
          this.state.selectedDate === null ? firstDate : this.state.selectedDate,
        ServicerDealName: this.state.dealName,
        ServicerDashboardName: tableMeta.rowData[0],
      },
    });
  };

  General = (value) => {
    sessionStorage.setItem("dealname", value);
    this.props.history.push({
      pathname: "/admin/general",
      state: { checkDeal: false },
    });
  };

  servicerGetalldeals = async () => {
    var data = {};
    data.ServicerName = "Servicer";
    data.peer = this.state.peer;

    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await servicerGetalldeals(data);

    if (APIResponse.status === 200) {
      this.setState(
        {
          getLoansLoader: false,
          tableData: APIResponse.data,
          loading: false,
        },
        () => {
          const dealData = APIResponse.data;
          if (dealData.length > 0) {
            const getDealNames = dealData.map((item) => item["Deal Name"]);
            const getPaymentDates = dealData.map(
              (item) => item["Payment Date"]
            )[0];
            console.log("getpaymentdates", getPaymentDates);
            const getServicers = dealData.map((item) => item["Servicer"])[0];
            // const getVersions = dealData.map((item) => item["Version"])[0];
            sessionStorage.setItem("dealname", getDealNames[0]);
            sessionStorage.setItem("isLoansProcessed", "Yes");
            console.log(">>>>>>>>>>>>>>>>>>", getPaymentDates[0]);
            console.log("check", getDealNames[0]);
            sessionStorage.setItem(
              "nextpaymentdate",
              getPaymentDates[getPaymentDates.length - 1]
            );
            sessionStorage.setItem("lastpaymentdate", "");
            sessionStorage.setItem("Servicer", getServicers[0]);
            // sessionStorage.setItem("version", getVersions[0]);
            sessionStorage.setItem(
              "getDashboardDeals",
              JSON.stringify(getDealNames)
            );
            sessionStorage.setItem(
              "getDashboardDates",
              JSON.stringify(getPaymentDates)
            );
            // sessionStorage.setItem(
            //   "getDashboardVersions",
            //   JSON.stringify(getVersions)
            // );
            sessionStorage.setItem("dashChanges", true);
          } else {
            console.log("Deal Name or Payment Date is missing.");
            sessionStorage.setItem("dealname", "");
            sessionStorage.setItem("isLoansProcessed", "No");
            sessionStorage.setItem("nextpaymentdate", "");
            sessionStorage.setItem("lastpaymentdate", "");
            sessionStorage.setItem("Servicer", "");
            // sessionStorage.setItem("version", "");
            sessionStorage.setItem("getDashboardDeals", "");
            sessionStorage.setItem("getDashboardDates", "");
            // sessionStorage.setItem("getDashboardVersions", "");
            sessionStorage.setItem("dashChanges", false);
          }
        }
      );

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event, value, rowIndex, tableData) => {
    const newValue = event.target.value;
    const { selectedDate } = this.state;
    console.log("newValue", newValue);
    const updatedPaymentDates = [...this.state.paymentDates];
    updatedPaymentDates[rowIndex] = newValue;

    // console.log("PaymentDates", value);

    console.log("updatedPaymentDates", updatedPaymentDates);

    this.setState(
      {
        getlastPaymentDates: value,
        isDateChanged: true,
        getPaymentDateIndex: rowIndex,
        PaymentDate: newValue,
        selectedDate: selectedDate,
        openModalPayment: true,
        paymentDates: updatedPaymentDates,
        singleRowPaymentDate: tableData,
      },
      () => sessionStorage.setItem("viewsummarydate", this.state.selectedDate)
    );
  };

  updatePaymentDateValue = async (newValue, rowData) => {
    console.log("newValue", newValue, rowData);
    var data = {};
    var input = {};

    input["Deal Name"] = rowData[0];
    input["Payment Date"] = rowData[4]; // Generate dynamically
    input["Loans Processed"] = rowData[5];
    input["Last Modified"] = rowData[6];
    input["Asset Class"] = rowData[1];
    input["Servicer"] = rowData[2];
    input["Error Status"] = rowData[7];

    console.log("input", input);
    data.input = input;

    data.selectedDate = newValue; // Assuming newValue is the selected date
    data.peers = this.state.peers; // Assuming peers are already set in the state
    console.log("data", data);

    this.setState({
      singleRowPaymentDate: rowData,
      selectedDate: newValue,
      dealName: rowData[0],
    });

    const APIResponse = await getDealDetailsByPaymentDateservicer(data);
    console.log("APIResponse", APIResponse);

    if (APIResponse.status === 200) {
      const res = APIResponse.data;
      const modifiedData = this.state.tableData.map((item) => {
        if (item["Deal Name"] === rowData[0]) {
          return res;
        } else {
          return item;
        }
      });
      console.log("modifiedData", modifiedData);
      this.setState(
        {
          getLoansLoader: false,
          tableData: modifiedData,
          loading: false,
          openModalPayment: false,
        },
        () => {
          console.log("singleRowPaymentDate", this.state.tableData);
        }
      );
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      console.log("", this.state.NextPaymentDate);

      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  onchange = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
  
    // Reset the page to 1 whenever search is changed
    this.setState({ searchText: searchText, currentPage: 1 });
  };
  searchBar = () => (
    <div className="search-bar-main-container" id="searchBox">
      <div className="tableSearch1">
        <TextField
          value={this.state.searchText}
          onChange={this.onchange}
          placeholder="Search"
          color="#212121"
          variant="standard"
          size="small"
          autoFocus
        />
        {/* {this.state.searchTextDashboard.length !== 0 ? <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchTextDashboard: '' })} /> : ''} */}
      </div>
      <button
        type="button"
        onClick={this.onClickCloseSearchBar}
        className="close-mui-icon"
      >
        <CloseIcon />
      </button>
    </div>
  );

  onClickCloseSearchBar = () => {
    this.setState({ searchText: "" });
    this.setState({ showSearchBox: false });
  };
  onClickSearchButton = () => {
    this.setState({ showSearchBox: true });
  };

  componentDidUpdate(prevProps, prevState) {
    // Check if the PaymentDate state has changed
    if (
      prevState.PaymentDate !== this.state.PaymentDate ||
      prevState.getPaymentDateIndex !== this.state.getPaymentDateIndex
    ) {
      //|| prevState.singleRowPaymentDate !== this.state.singleRowPaymentDate
      // Perform the API call or other logic here
      const { PaymentDate, singleRowPaymentDate, selectedDate, dealName } =
        this.state;
      console.log("rowData", singleRowPaymentDate);

      this.updatePaymentDateValue(
        PaymentDate,
        singleRowPaymentDate,
        selectedDate,
        dealName
      );
    }
  }

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.setItem("selectdate", this.state.detailsDateViewDetails);
    sessionStorage.removeItem("selectservicerdate");
    sessionStorage.removeItem("servicerdealname");
    sessionStorage.removeItem("servicerdashboardname");
    sessionStorage.removeItem("dealname");
    this.servicerGetalldeals();
  }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: "none !important",
            // height: "1.5rem !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontFamily: "Catamaran !important",
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            // padding: "0px 20px !important",
            // width:"20rem",
            fontWeight: "600 !important",
            fontFamily: "Mulish !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        // MUIDataTablePagination: {
        //   tableCellContainer: {
        //     borderBottom: "none !important",
        //   },
        //   navContainer: {
        //     justifyContent: "center",
        //   },
        //   toolbar: {
        //     paddingLeft: "50px !important",
        //   },
        // },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        // MuiTablePagination: {
        //   caption: {
        //     color: "#8C8C8C",
        //     marginRight: `${
        //       this.state.currentPage >= 1 && this.state.currentPage <= 9
        //         ? "-138"
        //         : this.state.currentPage >= 10
        //         ? "-142"
        //         : "-130"
        //     }px`,
        //     fontSize: "0.80rem",
        //   },
        // },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
        MuiInput: {
          root: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            padding: "5px !important",
          },
          underline: {
            "&:after": {
              borderBottom: "none !important",
            },
            "&:before": {
              borderBottom: "none !important",
            },
          },
        },
      },
    });
  render() {
    const filteredData = this.paginateData(); // Get the current paginated data based on search
    let data = this.state.searchText ? filteredData : this.state.tableData
    const totalFilteredCount = data.filter((item) => {
      return Object.values(item).some((value) =>
        value
          .toString()
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase())
      );
    }).length;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      onRowHover: this.onRowHover,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        const lowercaseSearchQuery = searchQuery.toLowerCase();

        // Assuming the "Deal Name" column is the first column (index 0)
        const dealName =
          currentRow[0] !== undefined
            ? currentRow[0].toString().toLowerCase()
            : "";

        // Check if the "Deal Name" contains the search query
        const isFound = dealName.includes(lowercaseSearchQuery);

        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Deal Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const getAllDates = (date, Index, tableMeta) => {
      console.log("date", date, Index, tableMeta);
      const changeDateFormat =
        Index === tableMeta.rowIndex
          ? moment(date).format("MM/DD/YYYY").toString()
          : null;
      this.setState({ dateNeeded: Index });
      console.log("changeDateFormat", changeDateFormat);
      return changeDateFormat;
    };

    const getIndex = (index) => {
      console.log("index", index);
      this.setState({ isDateSelected: index });
      return index;
    };

    const truncateString = (value, maxLength = 20) => {
      if (typeof value === "string" && value.length > maxLength) {
        return `${value.substring(0, maxLength)}...`;
      }
      return value;
    };
    const truncateStringdealname = (value, maxLength = 20) => {
      if (typeof value === "string" && value.length > maxLength) {
        return `${value.substring(0, maxLength)}...`;
      }
      return value;
    };

    const fontSizeStyle = {
      fontSize: "15px", // Adjust the font size as needed
    };

    const columns = [
      {
        name: "Deal Name",
        label: "Deal Name",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            // Assuming Error Status is in column 5 (index 4)
            const isError = tableMeta.rowData[4] === "Yes";
      
            return (
              <div
                style={{
                  ...fontSizeStyle,
                  position: "relative",
                  borderLeft: isError ? "4px solid transparent" : "none",
                  paddingLeft: isError ? "8px" : "4px",  // Adjust padding based on whether there's an error
                }}
              >
                {isError && (
                  <img
                    src={borderLeft}
                    alt="Error"
                    style={{
                      position: "absolute",
                      left: "-12px",  // Adjust position to place the image correctly
                      top: "50%",
                      transform: "translateY(-50%)",
                      height: "16px",  // Set the size of the error icon
                      width: "80% 8px",
                    }}
                  />
                )}
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                  title={value}
                >
                  {truncateStringdealname(value)}
                </div>
              </div>
            );
          },
        },
      },
      
      
      {
        name: "Asset Class",
        label: "Asset Class",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (
            <div style={fontSizeStyle}>{truncateStringdealname(value)}</div>
          ),
        },
      },
      {
        name: "Servicer",
        label: "Servicer",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (
            <div style={fontSizeStyle}>{truncateStringdealname(value)}</div>
          ),
        },
      },
      {
        name: "Last Payment Date",
        label: "Last Payment Date",
        options: {
          filter: true,
          sort: true,
          display: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log("value", value);
            return (
              <React.Fragment>
                <div className="align-actions1">
                  {value !== "" ? (
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        margin="normal"
                        id="date1"
                        value={
                          this.state.LastPaymentDate === ""
                            ? value
                            : this.state.LastPaymentDate
                        }
                        onChange={(date) =>
                          this.setState({
                            LastPaymentDate: moment(date)
                              .format("MM/DD/YYYY")
                              .toString(),
                          })
                        }
                        keyboard
                        placeholder="MM/DD/YYYY"
                        format={"MM/DD/YYYY"}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        autoOk={true}
                        clearable
                        variant="filled"
                        helperText={""}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Payment Date",
        label: "Payment Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            console.log("paymentDate", value, tableMeta);

            return (
              <React.Fragment>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "left",
                    // paddingRight: "4rem",
                    ...fontSizeStyle,
                  }}
                >
                  <select
                    className="input-select-general-new2"
                    value={this.state.paymentDates[tableMeta.rowIndex]}
                    onChange={(e) =>
                      this.handlePaymentDateChange(
                        e,
                        value,
                        tableMeta.rowIndex,
                        tableMeta.rowData
                      )
                    }
                  >
                    {value.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Loans Processed",
        label: "Processed",
        options: {
          filter: true,
          sort: true,

          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                paddingRight: "4rem",
                ...fontSizeStyle,
              }}
            >
              <tr>{value}</tr>
            </div>
          ),
        },
      },
      {
        name: "Last Modified",
        label: "Last Modified",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "4rem",
                ...fontSizeStyle,
              }}
            >
              <tr>{value}</tr>
            </div>
          ),
        },
      },
      {
        name: "Error Status",
        label: "Loan Data",
        options: {
          filter: false,
          sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                paddingTop: "5px",
                paddingLeft: "6rem",
                paddingRight: "-4rem",
                fontSize: "15px",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            const isError = tableMeta.rowData.showError;
            // const isError = true;
            console.log("tabe meta", tableMeta.rowData[5]);
            return (
              <React.Fragment>
                <div className="align-actions-servicer">
                  {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={this.onOpenModal1.bind(this)}> */}
                  {/* <button className="popupdashboard" onClick={() => this.Servicer()}>View Servicers</button> */}

                  {tableMeta.rowData[5] == "Yes" ? (
                    <button
                      className={`popupdashboard-servicer ${
                        isError ? {borderBottom} : ""
                      }`}
                      onClick={() => this.Recurring(value, tableMeta)}
                    >
                      View
                    </button>
                  ) : (
                    <button
                      className="login-sign_up-links popupdashboard-servicer-button-disabled"
                      onClick={() => this.Recurring(value, tableMeta)}
                      disabled
                    >
                      View
                    </button>
                  )}

                  <button
                    className="popupdashboard-servicer-process"
                    onClick={() => this.UploadLoanTape(value, tableMeta)}
                  >
                    Process
                  </button>

                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
    ];
    return (
      <div className="page">
        <Sidebar1 activeComponent={"Servicer Dashboard"} />
        <div className="content">
          
          <div className="page-content">
            <div className="row row1">
              <div className="investor-heading-container">
                <h1 className="headerdashboard-trustee">DASHBOARD</h1>
              </div>
              <div>
                <div className="dashboard-top-right-container1">
                  <div className="search-bar-outer-container uw-search-button-container">
                    {this.state.showSearchBox == true ? (
                      this.searchBar()
                    ) : (
                      <Button
                        className="search-mui-icons"
                        style={{ position: "relative" }}
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={this.onClickSearchButton}
                      >
                        Search
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <React.Fragment>
              <div className="workbench-table-container">
                <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                    // title={'Dashboard'}
                    data={filteredData}
                    columns={columns}
                    options={options}
                  />
                  <Stack spacing={2}>
                  <Pagination
                      count={Math.ceil(
                        totalFilteredCount / this.state.rowsPerPage
                      )} // Use the filtered count for pagination
                      className="pagination-container"
                      page={this.state.currentPage}
                      onChange={this.handlePageChange}
                    />
                  </Stack>
                </MuiThemeProvider>
              </div>
              <ReactModal
                isOpen={this.state.openModalPayment}
                contentLabel="Minimal Modal Example"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed", // Use fixed positioning for the overlay
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                  },
                  content: {
                    position: "absolute",
                    background: "transparent", // Make the modal content transparent
                    border: "none", // Optional: Remove any borders
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    overflow: "hidden",
                  },
                }}
              >
                <React.Fragment>
                  <img
                    src={BeanEater}
                    style={{
                      width: "8vw",
                      height: "9vh",
                      backgroundColor: "transparent",
                    }}
                  />
                  <h3 className="dot-loader">Loading.Please wait</h3>
                </React.Fragment>
              </ReactModal>
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(ServicerMainDashboard);
