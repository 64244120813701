// Message.js
import React from "react";
import styles from "./Message.module.scss";

function Message({ user, message, classs }) {
  // console.log(">>>>>>>>>>>", message);

  // Check if the message is a valid JSON string
  let data;
  try {
    data = JSON.parse(message);
  } catch (error) {
    // If parsing fails, treat the message as a plain string
    // console.log({ message });
    return (
      <div>
        <div className={`${styles.msgBox} ${styles[classs]}`}>{message}</div>
      </div>
    );
  }

  // Check if the data is in the expected format
  if (
    data &&
    data.response &&
    Array.isArray(data.response) &&
    Array.isArray(data.response[0])
  ) {
    // Extract headers and rows from the response
    const [headers, ...rows] = data.response;

    // Render a table
    return (
      <div>
        <table
          className={`${
            headers.length < 2 ? styles.msgBox : styles.msgBoxMaxWidth
          } ${styles[classs]}`}
        >
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    // If the data is not in the expected format, treat the message as a plain string
    return (
      <div>
        <div className={`${styles.msgBox} ${styles[classs]}`}>{message}</div>
      </div>
    );
  }
}

export default Message;
