import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Strats from "./Strats";
import {
  fetchPaymentDateFilters,
  fetchPrimaryStartFilters,
} from "../../../../../servies/services";
import WaveLoad from "../../../../../images/Wave_Load.svg";

// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

export default function StratsDropDowns({
  selectedDeals,
  selectedIssuer,
  selectedAssetClass,
  selectedDates,
  startDate,
  endDate,
}) {
  const [OptionsPaymentDates, setOptionsPaymentDates] = useState([]);
  const [OptionsPrimaryStarts, setOptionsPrimaryStarts] = useState([]);
  const [selectedAsOf, setSelectedAsOf] = useState({
    label: "Dec-22",
    value: "2022-12",
  });
  const [selectedPrimary, setSelectedPrimary] = useState([
    { label: "Balloon Flag", value: "Balloon Flag" },
  ]);
  const [selectedSecondary, setSelectedSecondary] = useState();
  //   {
  //   label: "Property State",
  //   value: "Property State",
  // }
  const [selectedTertiary, setSelectedTertiary] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set to true

  // Fetch filters when selectedDeals change
  useEffect(() => {
    const fetchFilters = async () => {
      setLoading(true); // Set loading to true before making API calls
      try {
        const requestData = {
          dealId: selectedDeals[0].value,
        };

        const requestDataFilter = {
          dealId: selectedDeals[0].value,
          month: selectedAsOf.label,
        };

        // Fetch payment date filters
        const response = await fetchPaymentDateFilters(requestData);
        const resultData = response?.data?.result || [];
        const paymentDateOptions = resultData.map(
          ({ field_name, field_value }) => ({
            label: field_name,
            value: field_value,
          })
        );
        setOptionsPaymentDates(paymentDateOptions);

        // Fetch primary strat filters
        const primaryResponse = await fetchPrimaryStartFilters(
          requestDataFilter
        );
        const primaryResultData = primaryResponse?.data?.result || [];
        const primaryStartsOptions = primaryResultData.map((field_value) => ({
          label: field_value,
          value: field_value,
        }));
        setOptionsPrimaryStarts(primaryStartsOptions);

        setLoading(false); // Set loading to false after all API calls are done
      } catch (error) {
        console.error("Error fetching filters:", error);
        setLoading(false); // Even if there's an error, loading should stop
      }
    };

    fetchFilters();
  }, [selectedAsOf, selectedDeals]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid black",
      borderRadius: "8px",
      minHeight: "38px",
      boxShadow: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#adaeaa",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    indicatorSeparator: (provided) => ({
      display: "none",
    }),
  };

  const selectedValues = [
    ...selectedPrimary.map((item) => item.value),
    selectedSecondary?.value,
    selectedTertiary?.value,
  ].filter(Boolean);

  return (
    <div>
      <div className="align-strats-dropdowns">
        <div className="align-strat-side-dropdowns">
          <div>
            <h6>As of</h6>
            <div className="align-strat-tabs">
              <Select
                options={OptionsPaymentDates}
                value={selectedAsOf}
                onChange={setSelectedAsOf}
                isClearable
                placeholder="Select any one"
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
          <div>
            <h6>Primary Strat</h6>
            <div className="align-strat-tabs-primary">
              <MultiSelect
                options={OptionsPrimaryStarts}
                value={selectedPrimary}
                onChange={setSelectedPrimary}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="custom-multiselect"
              />
            </div>
          </div>
          <div>
            <h6>Secondary Strat</h6>
            <div className="align-strat-tabs">
              <Select
                options={OptionsPrimaryStarts.filter(
                  (option) => !selectedValues.includes(option.value)
                )}
                value={selectedSecondary}
                onChange={setSelectedSecondary}
                isClearable
                placeholder="Select any one"
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
          <div>
            <h6>Tertiary Strat</h6>
            <div className="align-strat-tabs">
              <Select
                options={OptionsPrimaryStarts.filter(
                  (option) => !selectedValues.includes(option.value)
                )}
                value={selectedTertiary}
                onChange={setSelectedTertiary}
                isClearable
                placeholder="Select any one"
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Conditionally render Strats component */}
      {!loading && (
        <Strats
          selectedAsOf={selectedAsOf}
          selectedPrimary={selectedPrimary}
          selectedSecondary={selectedSecondary}
          selectedTertiary={selectedTertiary}
          selectedDeals={selectedDeals}
          selectedIssuer={selectedIssuer}
          selectedAssetClass={selectedAssetClass}
          selectedDates={selectedDates}
          startDate={startDate}
          endDate={endDate}
          loading={loading}
        />
      )}
      {loading && (
        <div>
          <img
            src={WaveLoad}
            style={{
              width: "100%",
              height: "10vh",
              backgroundColor: "#fafafa",
            }}
            alt="Loading Animation"
          />
        </div>
      )}
    </div>
  );
}
