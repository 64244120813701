import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import DoubleRing1 from "../../images/DoubleRing1.svg";

export default class Loader extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="LinearProgress">
          {this.props.msg === undefined ? (
            <>
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "white",
                }}
              >
                <img
                  src={DoubleRing1}
                  style={{
                    width: "100%",
                    height: "10vh",
                    backgroundColor: "#fafafa",
                  }}
                  alt="Loading Animation"
                />
                {/* <h4 className="commonLoading">Loading</h4> */}
                <h4 className="loadingMapField">Please wait </h4>
              </div>
            </>
          ) : this.props.msg === "" ? (
            <LinearProgress thickness={2} color="secondary" />
          ) : (
            <div className="loadingTextLinear">
              <img
                src={DoubleRing1}
                style={{
                  width: "100%",
                  height: "10vh",
                  backgroundColor: "white",
                }}
                alt="Loading Animation"
              />
              <h4>{this.props.msg}</h4>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
