import React, { useState, useRef, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import SupportWindow from "../../../../../components/SupportWindow/SupportWindow";

const ChatAssistant = () => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);

  // Close the SupportWindow if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTextFieldClick = () => {
    setVisible(true);
  };

  const userName = sessionStorage.getItem("user_name") || "";
  const isInvestor = userName.includes("Investor");

  return (
    <Box
      sx={{
        flexGrow: 1,
        "& legend": { display: "none" },
        "& fieldset": { top: 0 },
      }}
    >
      {isInvestor && (
        <div
          className={`AI_Icon ${
            visible ? "issure-icon-chat" : "issure-icon-disable-chat"
          }`}
          ref={wrapperRef}
        >
          {/* <img
            alt="issuer icon"
            src="path/to/AIChat.png" // Replace with the actual path to the image
            onClick={() => setVisible(!visible)}
          />
          <h1 className="Beta" onClick={() => setVisible(!visible)}>
            BETA
          </h1> */}

          <SupportWindow visible={visible} />
        </div>
      )}
      <TextField
        variant="outlined"
        placeholder="Ida (Virtual Analyst) ~ Converse With Data"
        fullWidth
        onClick={handleTextFieldClick}
        InputProps={{
          sx: {
            borderRadius: "20px",
            height: "38px",
            padding: "0 10px",
            fontFamily: "Mulish, sans-serif",
          },
        }}
        sx={{
          width: "340px !important",
          "& .MuiInputBase-input::placeholder": {
            fontFamily: "Mulish, sans-serif",
            fontSize: "14px",
          },
        }}
      />
    </Box>
  );
};

export default ChatAssistant;
