/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import { withSnackbar } from "notistack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    customStylesauto
  } from "../../../../../components/customscripts/customscript";
import CloseIcon from "@material-ui/icons/Close";
import { customStylesautosmallmodal1 } from "../../../../../components/customscripts/customscript";
import FormLoader from "../../../../../components/loader/formLoader";

import {
    InvestorReportData,
    exportAsPDF
} from "../../../../../servies/services";
import NumberComp3 from "../../../../../components/NumberComp3";
import * as moment from "moment";
import ReactModal from "react-modal";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import Rectangle from "../../../../../images/Rectangle555.png";
import Groups from "../../../../../images/Group4345.png";
import ThinkingPeople from "../../../../../images/ThinkingPeople.png";
import $ from 'jquery';

class InvestorReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      tableData: {
        DealContactInformation: {},
      },
      servicerData: [],
      screenloader: false,
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      TableName: "PaymentRules",
      peers: JSON.parse(sessionStorage.getItem("peers")),
      peer: sessionStorage.getItem("peer"),
      formData: {
        Name: "",
        LastModified: "",
      },
      formData1: {
        Name: "",
        LastModified: "",
      },
      deleteRowVal: {
        Name: "",
      },
      getLoansLoader: false,
      openPopup: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      searchText: "",
      rowsSelected: null,
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: true,
    };
  }
  showPrev = () => {
    window.location.assign("/admin/payment_recurring");
  };
  showNext = () => {
    // window.location.assign("/admin/tranches_recurring");
  };
  popoverBottom = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={() => this.PaymentRules(this.state.DealName)}>
          Edit Deal
        </button>
        <hr className="servicer-popover-hr" />
        <button
          onClick={() =>
            this.Loans(this.state.DealName, this.state?.NextPaymentDate)
          }
        >
          Loan Tape
        </button>
        <hr className="servicer-popover-hr" />
        <button>Change Period</button>
      </Popover>
    );
  };

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  onOpenModal = () => {
    this.setState({ open1: true });
  };
  onCloseModal = () => {
    this.setState({ open1: false });
  };
  //   onOpenModal1 = () => {
  //     this.setState({ open2: true });
  //   };
  onCloseModal1 = () => {
    this.setState({ open2: false });
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/admin/reports",
    });
  };

  handleClickGeneral = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    window.location.assign("/admin/general_recurring");
  };

  handleClickTranches = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    window.location.assign("/admin/tranches_recurring");
  };
  handleClickFees = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    window.location.assign("/admin/fees_recurring");
  };
  handleClickExpenses = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    window.location.assign("/admin/expenses_recurring");
  };
  handleClickAccounts = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    window.location.assign("/admin/accounts_recurring");
  };
  handleClickTests = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    window.location.assign("/admin/tests_recurring");
  };
  handleClickVariables = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: true,
      activeInsights8: false,
      activeInsights9: false,
    });
    window.location.assign("/admin/variable_recurring");
  };
  handleClickPaymentRules = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: true,
      activeInsights9: false,
    });
    window.location.assign("/admin/payment_recurring");
  };
  handleClickReports = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: true,
    });
  };

  //   CalculatePayments = async () => {
  //     this.setState({ getLoansLoader: true, loading: true });
  //     let x = this.state?.NextPaymentDate;
  //     let month = x.slice(0, 2);
  //     let year = x.slice(6, 10);
  //     var data = {};
  //     data.DealName = this.state.DealName;
  //     data.Month = month;
  //     data.Year = year;
  //     data.peers = this.state.peers;
  //     data.CurrentPaymentDate = this.state?.NextPaymentDate;

  //     console.log("datata", data);
  //     const APIResponse = await CalculatePayments(data);

  //     console.log("feesCalculatePayments", APIResponse.data);
  //     if (APIResponse.status === 200) {
  //       if (APIResponse.data.isSuccess === true) {
  //         this.setState({
  //           getLoansLoader: false,
  //           loading: false,
  //         });
  //         const message = APIResponse.data.message;
  //         this.props.enqueueSnackbar(message, {
  //           variant: "info",
  //           autoHideDuration: 3000,
  //         });
  //       } else {
  //         const message = APIResponse.data.message;
  //         this.props.enqueueSnackbar(message, {
  //           variant: "error",
  //           autoHideDuration: 3000,
  //         });
  //       }
  //     }
  //     // else if (APIResponse.status === 201) {
  //     //   this.setState({ getLoansLoader: false, loading: false });
  //     //   const message = APIResponse.data.message;
  //     //   this.props.enqueueSnackbar(message, {
  //     //     variant: "error",
  //     //     autoHideDuration: 3000,
  //     //   });
  //     // }
  //     else {
  //       this.setState({ getLoansLoader: false, loading: false });
  //       const message = APIResponse.data.message;
  //       this.props.enqueueSnackbar(message, {
  //         variant: "error",
  //         autoHideDuration: 3000,
  //       });
  //     }
  //   };

  PaymentRules = (value) => {
    sessionStorage.setItem("dealname", value);
    this.props.history.push({
      pathname: "/admin/paymentrules",
      // state:{checkDeal:false}
    });
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: { checkRecurring: true, Month: month, Year: year, ViewDate: date },
    });
  };

  fistTableData = (tableData) => {
    console.log("tableData", tableData);
    return (
      <div>
        {tableData !== undefined ? (
          <table className="table-servicer">
            <tr>
              {Object.keys(tableData[0]).map((heading) => (
                <th className="servicer-data-table-heading">{heading}</th>
              ))}
            </tr>
            {tableData.map((data) => (
              <tr>
                {Object.keys(tableData[0]).map((element) => (
                  <td>{<NumberComp3 value={data[element]}></NumberComp3>}</td>
                ))}
              </tr>
            ))}
          </table>
        ) : (
          ""
        )}
      </div>
    );
  };

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
            zIndex: "1 !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
      },
    });

  ViewReports = (value, tableData) => {
    console.log("value: ", value, tableData);
    if (tableData.rowIndex === 0) {
      window.location.assign("/admin/viewInvestorReport");
    }
  };

  onOpenModal2 = (value, editValue) => {
    console.log("value: ", value, editValue);
    console.log(this.state.formData1);
    console.log("edit fees modal");
    // console.log('rateChange',editValue);
    if (this.state.ViewStatus === false) {
      const rateChange =
        editValue[2] !== "" ? editValue[2].replace(/%?/g, "") : editValue[2];
      this.setState(
        {
          EditPreviousName: value,
          open3: true,
          open2: false,
          formData1: {
            Name: value,
            LastModified: editValue[0],
          },
        },
        () => console.log(this.state.ViewStatus, this.state.formData1.Amount)
      );
    } else {
      this.setState(
        {
          open3: true,
          open2: false,
          ViewStatus: false,
          formData1: {
            ...this.state.formData1,
            Rate: this.state.formData1.Rate.replace(/%?/g, ""),
            Amount: this.state.formData1.Amount,
          },
        },
        () => console.log(this.state.ViewStatus)
      );
    }
  };

  exportPDF = async () => {
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    this.setState({ formLoader: true });
    const pdf = $("#InvestorId").html();
    console.log("data", JSON.stringify(pdf));
    let input = {};
    input.DealName = this.state.DealName;
    input.Month = month;
    input.Year = year;
    input.data = JSON.stringify(pdf);
    const APIResponse = await exportAsPDF(input);
    console.log("generalDealRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({ formLoader: false });
      const message = "PDF Generated Successfully";
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      // const file_name = APIResponse.data.filename
      // let filepath = "";
      // filepath = process.env.react_app_base_url + "backendapistoa/" + file_name;
      // downloadFile(filepath);

      // function downloadFile(urlToSend) {
      //     var req = new XMLHttpRequest();
      //     req.open("GET", urlToSend, true);
      //     req.responseType = "blob";
      //     req.onload = function (event) {
      //         var blob = req.response;
      //         var fileName = file_name //if you have the fileName header available
      //         var link = document.createElement('a');
      //         link.href = window.URL.createObjectURL(blob);
      //         link.download = fileName;
      //         link.click();
      //     };

      //     req.send();
      // }
    } else {
      this.setState({ formLoader: false });
      const message = "Something Went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  InvestorReportData = async () => {
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.NextPaymentDate = this.state?.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    this.setState({ screenloader: true });
    const APIResponse = await InvestorReportData(data);

    console.log("generalDealRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        tableData: APIResponse.data,
        servicerData: APIResponse.data,
        screenloader: false,
      });
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    this.InvestorReportData();
  }

  render() {
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const columns = [
      {
        name: "Name",
        label: "Name",
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: "SubName",
        label: "Sub Name",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "AvailableFunds",
        label: "Available Funds",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "PayableAmount",
        label: "Payable Amount",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Limit",
        label: "Limit",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Paid",
        label: "Paid",
        options: {
          filter: false,
          sort: false,
        },
      },
      // {
      //   name: "Name",
      //   label: "Actions",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customHeadRender: (columnMeta, updateDirection) => (
      //       <th
      //         style={{
      //           backgroundColor: "rgba(1, 142, 130, 0.1)",
      //           borderBottom: "none",
      //           paddingBottom: "5px",
      //           paddingTop: "5px",
      //           paddingLeft: "20px",
      //         }}
      //       >
      //         {columnMeta.label}
      //       </th>
      //     ),
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <React.Fragment>
      //           <div className="">
      //             <span>
      //               {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={this.onOpenModal1.bind(this)}> */}
      //               <button
      //                 className="popupbutton1"
      //                 onClick={() =>
      //                   this.ViewReports(value, tableMeta)
      //                 }
      //               >
      //                 View
      //               </button>
      //               {/* <button
      //                 className="popupbutton1"
      //                 onClick={() =>
      //                   this.onOpenModal2(value, tableMeta.rowData.splice(1, 4))
      //                 }
      //               >
      //                 /Edit
      //               </button> */}
      //               {/* <button
      //                 className="popupbuttons1"
      //                 onClick={() =>
      //                   this.onOpenModal3(
      //                     value,
      //                     tableMeta.rowData.splice(1, 4)
      //                   )
      //                 }
      //               >
      //                 Delete
      //               </button> */}
      //             </span>
      //             {/* </Button> */}
      //           </div>
      //         </React.Fragment>
      //       );
      //     },
      //   },
      // },
    ];

    const columns1 = [
      {
        name: "Name",
        label: "Name",
        options: {
          filter: false,
          sort: false,
        },
      },

      {
        name: "InterestRate",
        label: "Interest Rate",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "AccrualDays",
        label: "Accrual Days",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "BeginningBalance",
        label: "Beginning Balance",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "BeginningInterestShortfall",
        label: "Beginning Interest Shortfall",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "InterestAccrued",
        label: "Interest Accrued",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "OptimaInterest",
        label: "Optima Interest",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "InterestPaid",
        label: "Interest Paid",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "PrincipalPaid",
        label: "Principal Paid",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "TotalDistribution",
        label: "Total Distribution",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "InterestAccrued",
        label: "Interest Accrued",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "LossAllocated",
        label: "Loss Allocated",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "EndingBalance",
        label: "Ending Balance",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "EndingInterestShortfall",
        label: "Ending Interest Shortfall",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "CumulativeLossAllocated",
        label: "Cumulative Loss Allocated",
        options: {
          filter: false,
          sort: false,
        },
      },
      // {
      //   name: "Name",
      //   label: "Actions",
      //   options: {
      //     filter: true,
      //     sort: false,
      //     customHeadRender: (columnMeta, updateDirection) => (
      //       <th
      //         style={{
      //           backgroundColor: "rgba(1, 142, 130, 0.1)",
      //           borderBottom: "none",
      //           paddingBottom: "5px",
      //           paddingTop: "5px",
      //           paddingLeft: "20px",
      //         }}
      //       >
      //         {columnMeta.label}
      //       </th>
      //     ),
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <React.Fragment>
      //           <div className="">
      //             <span>
      //               {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={this.onOpenModal1.bind(this)}> */}
      //               <button
      //                 className="popupbutton1"
      //                 onClick={() =>
      //                   this.ViewReports(value, tableMeta)
      //                 }
      //               >
      //                 View
      //               </button>
      //               {/* <button
      //                 className="popupbutton1"
      //                 onClick={() =>
      //                   this.onOpenModal2(value, tableMeta.rowData.splice(1, 4))
      //                 }
      //               >
      //                 /Edit
      //               </button> */}
      //               {/* <button
      //                 className="popupbuttons1"
      //                 onClick={() =>
      //                   this.onOpenModal3(
      //                     value,
      //                     tableMeta.rowData.splice(1, 4)
      //                   )
      //                 }
      //               >
      //                 Delete
      //               </button> */}
      //             </span>
      //             {/* </Button> */}
      //           </div>
      //         </React.Fragment>
      //       );
      //     },
      //   },
      // },
    ];
    console.log(this.state.tableData);
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Dashboard"} />
          <div className="content">
            
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div
                    className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1.5rem",
                      width: "90rem",
                    }}
                  >
                    <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                      <KeyboardBackspaceIcon
                        onClick={this.goBackToDashboard}
                        className="left-arrow-muis1 left-arrow-servicer"
                      ></KeyboardBackspaceIcon>
                      <h3 className="headerdashboard">
                        TRUSTEE INVESTOR REPORT
                      </h3>
                    </div>
                    {/* <div>
                    <label className="dealInfo">Deal Name </label>
                    <h5>{this.state.DealName}</h5>
                  </div>
                  <div>
                    <label className="dealInfo">Payment Date </label>
                    <h5>{this.state?.NextPaymentDate}</h5>
                  </div> */}
                  </div>
                  <div className="col-12 col-sm-6 col-md-5 hellocard">
                    <div className="buttonsverified uw-deal-details-button-container">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.exportPDF}
                      >
                        Export to PDF
                        {this.state.formLoader === true ? (
                          <CircularProgress size="22px" color="primary" />
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </div>
                </div>

                <React.Fragment>
                  <div id="InvestorId">
                    <div className="page-content1">
                      <h5 className="headingspace"></h5>

                      <div className="Investor-head">
                        <div className="Investor-content">
                          <img src={Rectangle} />

                          <h4>
                            {this.state.tableData.DealContactInformation.dealid}
                          </h4>
                          <div className="Inv-child">
                            <div>
                              <h6>Distribution Date</h6>
                              <h5>
                                {
                                  Object.values(
                                    this.state.tableData.DealContactInformation
                                  )[1]
                                }
                              </h5>
                            </div>

                            <div>
                              <h6>Report Type</h6>
                              <h5>
                                {
                                  Object.values(
                                    this.state.tableData.DealContactInformation
                                  )[2]
                                }
                              </h5>
                            </div>
                          </div>

                          <div className="Inv-child1">
                            <h3>Deal Contact Information</h3>

                            <div>
                              <h6>Relationship Manager</h6>
                              <h5>
                                {
                                  Object.values(
                                    this.state.tableData.DealContactInformation
                                  )[3]
                                }
                              </h5>
                            </div>

                            <div>
                              <h6>Address</h6>
                              <h5>
                                {
                                  Object.values(
                                    this.state.tableData.DealContactInformation
                                  )[4]
                                }
                              </h5>
                            </div>
                            <div>
                              <h6>Email</h6>
                              <h5>
                                {
                                  Object.values(
                                    this.state.tableData.DealContactInformation
                                  )[5]
                                }
                              </h5>
                            </div>

                            <div>
                              <h6>Website Reporting</h6>
                              <h5>
                                {
                                  Object.values(
                                    this.state.tableData.DealContactInformation
                                  )[6]
                                }
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="Investor-content1">
                          <img src={Groups} />
                        </div>
                      </div>
                    </div>

                    <div className="page-content1">
                      <h5 className="headingspace"></h5>
                      <div className="thinking">
                        <div className="think-left">
                          <img src={ThinkingPeople} />
                        </div>
                        <div className="think-right">
                          <h3 style={{ fontWeight: "bold" }}>Section Name</h3>
                          <h4>Payment Rules</h4>
                          <h4>Tranches</h4>
                        </div>
                        <div className="think-dots">
                          <div>
                            ...............................................................................................................................
                          </div>
                          <div>
                            ...............................................................................................................................
                          </div>
                        </div>
                        <div className="think-pages">
                          <h3 style={{ fontWeight: "bold" }}>Page No.</h3>
                          <h4>1</h4>
                          <h4>2</h4>
                        </div>
                      </div>
                    </div>

                    <div className="inv-table-content">
                      <h3 style={{ textAlign: "center" }}>Payment Rules</h3>
                      <React.Fragment>
                        <div className="outer-servicer-data-table-container ">
                          <div className="servicer-data-table-container servicer-first-table1">
                            {this.fistTableData(
                              this.state.servicerData.PaymentRules
                            )}
                          </div>
                        </div>
                      </React.Fragment>

                      <h3 style={{ textAlign: "center" }}>Tranches</h3>
                      <React.Fragment>
                        <div className="outer-servicer-data-table-container ">
                          <div className="servicer-data-table-container servicer-second-table1">
                            {this.fistTableData(
                              this.state.servicerData.Tranches
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(InvestorReport);
