/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import MUIDataTable from "mui-datatables";
import { TrainRounded } from "@material-ui/icons";
import Sidebar1 from "../../../components/sidebar/sidebar";
import { withSnackbar } from "notistack";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextareaAutosize,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Loader from "../../../components/loader";
import UserHeader from "../../../components/header/UserHeader";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  getdefinition,
  deletedefinition,
  updatedefinition,
} from "../../../servies/services";
import CloseIcon from "@material-ui/icons/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Box from "@mui/material/Box";

class StdFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      AssetType: sessionStorage.getItem("assettype"),
      loading: true,
      isStdFields: true,
      getLoansLoader: false,
      message: "",
      tabledata: [
        {
          id: "",
          def: "",
          fieldcode: "",
          section: "",
          descp: "",
          "ND1-ND4 allowed?": "",
          "ND5 allowed?": "",
          format: "",
        },
      ],
      newField: {
        id: "",
        def: "",
        fieldcode: "",
        section: "",
        descp: "",
        "ND1-ND4 allowed?": "",
        "ND5 allowed?": "",
        format: "",
      },
      updatedtable: [],
      ApproveStatus: "",
      OrgName: "",
      status: "",
      selectedOption: "",
      isTableVisible: false,
      LastUpdate: "",
      currentlyEditedRow: -1,
      editingRows: [],
      showSearchBox: false,
      searchText: "",
      currentPage: 0,
      isEditMode: "none",
      selectableRows: false,
      originalTabledata: [],
      selectedAssetClass: "",
      assetClassCounters: {},
      fieldsDisabled: true,
      originalfieldcode: "",
      originalsection: "",
      options: [],
      originalRowData: Array.from({ length: 10 }, () => ({})),
    };
  }

  getdata = async (selectedOption) => {
    this.setState({ getLoansLoader: true, tabledata: [], loading: true }); // let x = this.state?.NextPaymentDate; // let month = x.slice(0, 2); // let year = x.slice(6, 10);//dealrecurring
    var data = {};
    data.AssetType = selectedOption;
    data.peer = this.state.peer;
    console.log("datata", data);
    const APIResponse = await getdefinition(data);
    //console.log("getDefinition", APIResponse.data);

    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tabledata: APIResponse.data,
        originalfieldcode: APIResponse.data[0].fieldcode,
        originalsection: APIResponse.data[0].section,
        loading: false,
        isEditMode: true,
        LastUpdate: APIResponse.data[0].lastupdate,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
      });
      const message = "Couldn't create the deal";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  handleAssetClassChange = async (event, APIResponse) => {
    const selectedAssetType = event.target.value;
    this.setState({
      selectedOption: selectedAssetType,
      fieldsDisabled: false,
    });
    if (selectedAssetType) {
      if (!this.state.assetClassCounters[selectedAssetType]) {
        this.setState((prevState) => ({
          assetClassCounters: {
            ...prevState.assetClassCounters,
            [selectedAssetType]: 0,
          },
        }));
      }
      const defaultRow = {
        def: "",
        fieldcode: "",
        section: "",
        descp: "",
        "ND1-ND4 allowed?": "",
        "ND5 allowed?": "",
        format: "",
      };
      let updatedTabledata = [...this.state.tabledata];
      if (updatedTabledata.length === 0) {
        updatedTabledata = [defaultRow];
      } else {
        updatedTabledata[0] = defaultRow;
      }
      this.setState({
        tabledata: updatedTabledata,
      });
      await this.getdata(selectedAssetType);
    }
  };
  handleRowEdit = (rowIndex) => {
    this.setState({
      currentlyEditedRow: rowIndex,
      isEditMode: "none",
    });
  };
  Edit = (index) => {
    const originalRowData = [...this.state.originalRowData];
    originalRowData[index] = { ...this.state.tabledata[index] };
    const originalTabledata = [...this.state.tabledata];
    const selectedSection = originalTabledata[index].section;
    this.setState({
      originalRowData,
      originalTabledata,
      currentlyEditedRow: index,
      selectedSection, // Store the selected section value
    });
    this.handleRowEdit(index);
  };
  handleFieldChange = (rowIndex, fieldName, value) => {
    const updatedTabledata = [...this.state.tabledata];
    updatedTabledata[rowIndex][fieldName] = value;
    this.setState({
      tabledata: updatedTabledata,
    });
  };
  Delete = async (index, fieldName) => {
    const { selectedOption, tabledata } = this.state;

    if (window.confirm("Are you sure you want to Delete?")) {
      // Make sure the index is within the bounds of the tabledata array
      if (index >= 0 && index < tabledata.length) {
        const removedRow = tabledata[index]; // Get the row at 'index'

        // Remove the row from the tabledata array
        const updatedTabledata = tabledata.filter((item, i) => i !== index);

        if (removedRow) {
          var senddata = [
            {
              id: removedRow.id,
              def: removedRow.def,
              fieldcode: removedRow.fieldcode,
              section: removedRow.section,
              descp: removedRow.descp,
              "ND1-ND4 allowed?": removedRow["ND1-ND4 allowed?"],
              "ND5 allowed?": removedRow["ND5 allowed?"],
              format: removedRow["format"],
            },
          ];

          // Update the state to reflect the removal of the row
          this.setState({
            getLoansLoader: true,
            loading: true,
            tabledata: updatedTabledata,
          });

          var data = {
            AssetType: selectedOption,
            peers: this.state.peers,
            token: this.state.token,
            tabledata: senddata,
          };

          // Make the API call to delete the row
          const APIResponse = await deletedefinition(data);
          console.log("SaveDataAfterDeletion", APIResponse.data);
          if (APIResponse.status === 200) {
            const message = "Deleted Successfully";
            this.props.enqueueSnackbar(message, {
              variant: "info",
              autoHideDuration: 3000,
            });
            await this.getDefinitionAfterSave(selectedOption);
          }
        }
      } else {
        console.error("Invalid index:", index);
      }
    }
  };

  HIt = (idx) => {
    setTimeout(() => {
      console.log("setTimeout", Date().toLocaleString());
    }, 1000);
  };
  SaveData = async (index) => {
    const { selectedOption } = this.state;
    const updatedTabledata = [...this.state.tabledata];
    const updatedRow = [updatedTabledata[index]];
    const newIndex = index;
    if (index >= 0 && index < updatedTabledata.length) {
      console.log("updatedtabledata", updatedTabledata);
      this.setState({
        currentlyEditedRow: -1,
        isEditMode: "none",
        originalTabledata: [],
      });
      var senddata = [];
      senddata[updatedRow] = [
        {
          id: updatedTabledata[index].id,
          def: updatedTabledata[index].def,
          fieldcode: updatedTabledata[index].fieldcode,

          // (updatedRow.fieldcode = `${this.state.originalfieldcode}${
          //   newIndex + 1
          // }`),
          section: updatedTabledata[index].section,
          descp: updatedTabledata[index].descp,
          "ND1-ND4 allowed?": updatedTabledata[index]["ND1-ND4 allowed?"],
          "ND5 allowed?": updatedTabledata[index]["ND5 allowed?"],
          format: updatedTabledata[index]["format"],
        },
      ];
    }
    this.setState({ getLoansLoader: true, loading: true, tabledata: [] });
    var data = {};
    data.AssetType = selectedOption;
    data.peers = this.state.peers;
    data.token = this.state.token;
    data.tabledata = senddata[updatedRow];
    const APIResponse = await updatedefinition(data);
    console.log("SaveData", APIResponse.data);
    if (APIResponse.status === 200) {
      const message = "Updated Successfully";
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      await this.getDefinitionAfterSave(selectedOption);
    } else {
    }
  };
  getDefinitionAfterSave = async (selectedOption) => {
    const APIResponse = await getdefinition({
      AssetType: selectedOption,
      peer: this.state.peer,
    });
    console.log("getDefinition", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tabledata: APIResponse.data,
        originalfieldcode: APIResponse.data[0].fieldcode,
        originalsection: APIResponse.data[0].section,
        loading: false,
        LastUpdate: APIResponse.data[0].lastupdate,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
      });
      const message = "Couldn't create the deal";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  Cancel = (index) => {
    const updatedTabledata = [...this.state.tabledata];
    const originalRowData = [...this.state.originalRowData];
    if (index >= 0 && index < updatedTabledata.length) {
      updatedTabledata[index] = { ...originalRowData[index] };
      this.setState({
        tabledata: updatedTabledata,
        currentlyEditedRow: -1,
        isEditMode: "none",
      });
    }
  };
  handleAddFields = () => {
    const selectedAssetClass = this.state.selectedOption;
    const currentCounter = this.state.assetClassCounters[selectedAssetClass];
    this.setState((prevState) => ({
      assetClassCounters: {
        ...prevState.assetClassCounters,
        [selectedAssetClass]: currentCounter + 1,
      },
    }));
    const newRow = {
      id: "",
      def: "",
      fieldcode: this.state.originalfieldcode,
      section: "",
      descp: "",
      "ND1-ND4 allowed?": "",
      "ND5 allowed?": "",
      format: "",
    };
    const totalRows = this.state.tabledata.length + 1;
    const rowsPerPage = 10;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const newPage = totalPages - 1;
    this.setState({ currentPage: newPage });
    this.setState((prevState) => ({
      tabledata: [...prevState.tabledata, newRow],
    }));
  };
  onchange = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
    this.setState({ searchText: searchText });
    console.log("search: e.target.value", this.state.searchText);
  };

  searchBar = () => (
    <div className="search-bar-main-container" id="searchBox">
      <div className="tableSearch1">
        <TextField
          value={this.state.searchText}
          onChange={this.onchange}
          placeholder="Search"
          variant="standard"
          size="small"
          style={{ marginLeft: "15px" }}
        />
      </div>
      <button
        type="button"
        onClick={this.onClickCloseSearchBar}
        className="close-mui-icon"
      >
        <CloseIcon />
      </button>
    </div>
  );
  onClickCloseSearchBar = () => {
    this.setState({ searchText: "" });
    this.setState({ showSearchBox: false });
  };
  onClickSearchButton = () => {
    this.setState({ showSearchBox: true });
    console.log("hellooo");
  };

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: "none !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
            height: "3rem",
          },
          toolButton: {
            fontFamily: "Catamaran !important",
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
        MuiInput: {
          root: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            padding: "5px !important",
          },
          underline: {
            "&:after": {
              borderBottom: "none !important",
            },
            "&:before": {
              borderBottom: "none !important",
            },
          },
        },
      },
    });
  render() {
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      //selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      onRowHover: this.onRowHover,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: 10,
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: TrainRounded,
      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },
      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },
      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        const lowercaseSearchQuery = searchQuery.toLowerCase();
        const dealName =
          currentRow[0] !== undefined
            ? currentRow[0].toString().toLowerCase()
            : "";
        const isFound = dealName.includes(lowercaseSearchQuery);
        return isFound;
      },
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Deal Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },
        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };
    const options1 = [
      "Residential Real Estate",
      "Commercial Mortgage",
      "Corporate Loans",
      "Auto Loans",
      "Consumer Loans",
      "Credit Cards",
      "Leasing",
      "Esoteric",
      "Non Performing Loans",
      "Default Fields",
    ];
    const options2 = [
      "Underlying exposures information section",
      "Collateral information section",
      "Tenant information section",
      "Historical collections information section",
      "Securitisation information section",
      "Cash-flow information section",
      "Asset Servicing Information",
    ];
    const { selectedOption } = this.state;
    const textFieldStyle = { width: "300px" };
    const columns = [
      {
        name: "def",
        label: "Field Name",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            if (this.state.currentlyEditedRow === tableMeta.rowIndex) {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <TextField
                    variant="outlined"
                    value={value}
                    onChange={(e) =>
                      this.handleFieldChange(
                        tableMeta.rowIndex,
                        "def",
                        e.target.value
                      )
                    }
                    style={textFieldStyle}
                    disabled={this.state.fieldsDisabled}
                  />
                </Box>
              );
            } else {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <TextField
                    variant="outlined"
                    value={value}
                    disabled
                    style={textFieldStyle}
                  />
                </Box>
              );
            }
          },
        },
      },
      {
        name: "fieldcode",
        label: "Field Code",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const index = tableMeta.rowIndex;
            if (this.state.currentlyEditedRow === tableMeta.rowIndex) {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <TextField
                    variant="outlined"
                    value={value}
                    // value={`${this.state.originalfieldcode}${index + 1}`}
                    onChange={(e) =>
                      this.handleFieldChange(
                        tableMeta.rowIndex,
                        "fieldcode",
                        e.target.value
                      )
                    }
                    style={textFieldStyle}
                    disabled={this.state.fieldsDisabled}
                  />
                </Box>
              );
            } else {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <TextField
                    variant="outlined"
                    value={value}
                    // value={`${this.state.originalfieldcode}${index + 1}`}
                    disabled
                    style={textFieldStyle}
                  />
                </Box>
              );
            }
          },
        },
      },
      {
        name: "section",
        label: "Section",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const inputId = `section-${tableMeta.rowIndex}`;
            if (this.state.currentlyEditedRow === tableMeta.rowIndex) {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <FormControl
                    className="input-select-general-new"
                    style={{ height: "50px" }}
                  >
                    <Select
                      variant="outlined"
                      id={inputId}
                      className="hide-arrow-icon"
                      value={value}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        this.setState({ selectedSection: selectedValue });
                        this.handleFieldChange(
                          tableMeta.rowIndex,
                          "section",
                          selectedValue
                        );
                      }}
                      disabled={this.state.fieldsDisabled}
                    >
                      {options2.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              );
            } else {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <FormControl
                    className="input-select-general-new"
                    style={{ height: "50px" }}
                  >
                    <Select
                      value={value}
                      disabled
                      id={inputId}
                      className="hide-arrow-icon"
                      variant="outlined"
                    >
                      {options2.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              );
            }
          },
        },
      },
      {
        name: "descp",
        label: "Content To Report",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const index = tableMeta.rowIndex;
            if (this.state.currentlyEditedRow === tableMeta.rowIndex) {
              return (
                <TextareaAutosize
                  variant="outlined"
                  size="small"
                  name={index}
                  value={this.state.tabledata[index].descp}
                  minRows={3}
                  style={{ height: "80px" }}
                  disabled={this.state.fieldsDisabled}
                  onChange={(e) =>
                    this.handleFieldChange(
                      tableMeta.rowIndex,
                      "descp",
                      e.target.value
                    )
                  }
                />
              );
            } else {
              return (
                <TextareaAutosize
                  variant="outlined"
                  minRows={3}
                  value={value}
                  disabled
                  style={{ height: "80px" }}
                />
              );
            }
          },
        },
      },
      {
        name: "ND1-ND4 allowed?",
        label: "ND1-ND4 allowed?",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const inputId = `section-${tableMeta.rowIndex}`;
            if (this.state.currentlyEditedRow === tableMeta.rowIndex) {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <FormControl
                    className="input-select-general-new"
                    style={{ height: "50px" }}
                  >
                    <Select
                      variant="outlined"
                      id={inputId}
                      className="hide-arrow-icon"
                      value={value}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        this.setState({ selectedSection: selectedValue });
                        this.handleFieldChange(
                          tableMeta.rowIndex,
                          "ND1-ND4 allowed?",
                          selectedValue
                        );
                      }}
                      disabled={this.state.fieldsDisabled}
                    >
                      <MenuItem key={"Yes"} value={"Yes"}>
                        Yes
                      </MenuItem>
                      <MenuItem key={"No"} value={"No"}>
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              );
            } else {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <FormControl
                    className="input-select-general-new"
                    style={{ height: "50px" }}
                  >
                    <Select
                      value={value}
                      disabled
                      id={inputId}
                      className="hide-arrow-icon"
                      variant="outlined"
                    >
                      <MenuItem key={"Yes"} value={"Yes"}>
                        Yes
                      </MenuItem>
                      <MenuItem key={"No"} value={"No"}>
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              );
            }
          },
        },
      },
      {
        name: "ND5 allowed?",
        label: "ND5 allowed?",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const inputId = `section-${tableMeta.rowIndex}`;
            if (this.state.currentlyEditedRow === tableMeta.rowIndex) {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <FormControl
                    className="input-select-general-new"
                    style={{ height: "50px" }}
                  >
                    <Select
                      variant="outlined"
                      id={inputId}
                      className="hide-arrow-icon"
                      value={value}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        this.setState({ selectedSection: selectedValue });
                        this.handleFieldChange(
                          tableMeta.rowIndex,
                          "ND5 allowed?",
                          selectedValue
                        );
                      }}
                      disabled={this.state.fieldsDisabled}
                    >
                      <MenuItem key={"Yes"} value={"Yes"}>
                        Yes
                      </MenuItem>
                      <MenuItem key={"No"} value={"No"}>
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              );
            } else {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <FormControl
                    className="input-select-general-new"
                    style={{ height: "50px" }}
                  >
                    <Select
                      value={value}
                      disabled
                      id={inputId}
                      className="hide-arrow-icon"
                      variant="outlined"
                    >
                      <MenuItem key={"Yes"} value={"Yes"}>
                        Yes
                      </MenuItem>
                      <MenuItem key={"No"} value={"No"}>
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              );
            }
          },
        },
      },
      {
        name: "format",
        label: "Format",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const index = tableMeta.rowIndex;
            if (this.state.currentlyEditedRow === tableMeta.rowIndex) {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <TextField
                    variant="outlined"
                    value={value}
                    // value={`${this.state.originalfieldcode}${index + 1}`}
                    onChange={(e) =>
                      this.handleFieldChange(
                        tableMeta.rowIndex,
                        "format",
                        e.target.value
                      )
                    }
                    style={textFieldStyle}
                    disabled={this.state.fieldsDisabled}
                  />
                </Box>
              );
            } else {
              return (
                <Box
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <TextField
                    variant="outlined"
                    value={value}
                    // value={`${this.state.originalfieldcode}${index + 1}`}
                    disabled
                    style={textFieldStyle}
                  />
                </Box>
              );
            }
          },
        },
      },
      {
        name: "Actions",
        label: "Actions",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue, index) => {
            const fieldName = tableMeta.rowData[0];
            return (
              <React.Fragment>
                {this.state.currentlyEditedRow === tableMeta.rowIndex ? (
                  <div className="parent-std-save">
                    <div className="enable-std-save">
                      <Button
                        color="primary"
                        variant="text"
                        size="small"
                        onClick={() => this.SaveData(tableMeta.rowIndex)}
                      >
                        Save
                      </Button>
                      <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        backgroundColor="green"
                        onClick={() => this.Cancel(tableMeta.rowIndex)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <img
                      alt=""
                      src={require("../../../images/edit.png")}
                      style={{ marginLeft: "-1px", cursor: "pointer" }}
                      height="25px"
                      width="25px"
                      x={index}
                      onClick={() => {
                        this.Edit(tableMeta.rowIndex);
                      }}
                      disabled={this.state.editingRows.includes(
                        tableMeta.rowIndex
                      )}
                    ></img>
                    <img
                      alt=""
                      src={require("../../../images/delete.png")}
                      style={{ marginLeft: "3px", cursor: "pointer" }}
                      height="25px"
                      width="25px"
                      x={index}
                      onClick={() => this.Delete(tableMeta.rowIndex, fieldName)}
                    ></img>
                  </div>
                )}
              </React.Fragment>
            );
          },
        },
      },
    ];
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"SetUpDashBoard"} />
          <div className="content">
            {/* {JSON.stringify(this.state.OrgName)} */}
            <div className="header">
              <UserHeader
                status={this.state.ApproveStatus}
                OrgName={this.state.orgname}
                isStdFields={this.state.isStdFields}
                pageTitle={this.state.status + " Add Standard Fields"}
              ></UserHeader>
            </div>
            <div
              className="tableSearch"
              style={{ backgroundColor: "white" }}
            ></div>
            <div
              style={{
                backgroundColor: "#d1f4f0",
                height: "90px",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginLeft: "80px",
                  width: "300px",
                  backgroundColor: "white",
                  borderRadius: "6px",
                }}
              >
                <InputLabel>Asset Class*</InputLabel>
                <Select
                  value={selectedOption}
                  onChange={this.handleAssetClassChange}
                  label="Select an option"
                >
                  {options1.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Last Update"
                variant="outlined"
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  marginTop: "20px",
                  width: "300px",
                  backgroundColor: "white",
                  borderRadius: "6px",
                }}
                value={this.state.LastUpdate}
                disabled
              />
            </div>
            {selectedOption && (
              <React.Fragment>
                <div className="investor-heading-container">
                  {this.state.showSearchBox == true ? (
                    this.searchBar()
                  ) : (
                    <h1
                      className="headerdashboard"
                      style={{
                        display: "flex",
                        marginLeft: "30px",
                        marginBottom: "-40px",
                      }}
                    >
                      Field Details
                    </h1>
                  )}
                </div>
                <div>
                  <div className="dashboard-top-right-container1">
                    <div className="search-bar-outer-container uw-search-button-container">
                      {this.state.showSearchBox == false && (
                        <button
                          className="search-mui-icons"
                          type="button"
                          onClick={this.onClickSearchButton}
                        >
                          <SearchOutlinedIcon />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="workbench-table-container">
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <div style={{ padding: "20px" }}>
                      <MUIDataTable
                        data={this.state.tabledata}
                        columns={columns}
                        options={{
                          ...options,
                          selectableRows: !this.state.isEditMode,
                          page: this.state.currentPage,
                        }}
                      />
                    </div>
                  </MuiThemeProvider>

                  <div className="btn_Std">
                    <div className="addfields" onClick={this.handleAddFields}>
                      + Add Fields
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withSnackbar(StdFields);
